import React, { Component } from 'react'
import './Header.scss'
import { Image } from '../../Image'
import GatsbyLink from '../../GatsbyLink'
import Carousel from '../../Carousel'
import { Breadcrumbs } from '../../Breadcrumbs'
import { decodeEntities } from '../../../utils/helpers'

class HeaderSlider extends Component {
  render() {
    const { slider, globalTitle, location, breadcrumbs, align } = this.props

    const settings = {
      container: 'page-carousel',
      nav: true,
      mouseDrag: true,
      items: 1,
      controls: true,
      loop: false,
      mode: 'gallery',
    }
    return (
      <div className="page-slider">
        <Carousel settings={settings}>
          {slider.map((slide, slide_count) => (
            <div
              key={slide_count}
              className={`slide${slide.subHeader ? ' title' : ''}${
                slide.hideOverlay ? '' : ' overlay-image'
              }`}
            >
              {!globalTitle && (
                <div className={`title-block ${align}`}>
                  <div className="wrap">
                    <div className="inner">
                      {breadcrumbs && location?.pathname !== '/' && (
                        <Breadcrumbs
                          location={location}
                          overwrite={slide.customBreadcrumb}
                        />
                      )}
                      {slide_count == 0 ? (
                        <h1
                          className="title"
                          dangerouslySetInnerHTML={{
                            __html: decodeEntities(slide.header),
                          }}
                        />
                      ) : (
                        <h2
                          className="title"
                          dangerouslySetInnerHTML={{
                            __html: decodeEntities(slide.header),
                          }}
                        />
                      )}
                      {slide.subHeader && (
                        <p
                          className="sub-title"
                          dangerouslySetInnerHTML={{
                            __html: decodeEntities(slide.subHeader),
                          }}
                        />
                      )}
                      <div className="actions">
                        {slide.action && slide.action.title && (
                          <GatsbyLink
                            to={slide.action.url}
                            className="action"
                            decode
                          >
                            {slide.action.title}
                          </GatsbyLink>
                        )}
                        {slide.actionTwo && (
                          <GatsbyLink
                            to={slide.actionTwo.url}
                            className="action"
                            decode
                          >
                            {slide.actionTwo.title}
                          </GatsbyLink>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {!slide?.action?.title &&
              !slide.subHeader &&
              slide?.action?.url ? (
                <GatsbyLink
                  to={slide.action.url}
                  className="action"
                  decode={false}
                >
                  <Image src={slide.background} className="background" />
                </GatsbyLink>
              ) : (
                <Image src={slide.background} className="background" />
              )}
            </div>
          ))}
        </Carousel>
      </div>
    )
  }
}

export const Header = props => {
  const { compact, globalTitle, breadcrumbs, header, subHeader, align } = props
  return (
    <section className={`page-header${compact ? ' compact' : ''}`}>
      <HeaderSlider {...props} />
      {globalTitle && (
        <div className={`title-block ${align}`}>
          <div className="wrap">
            <div className="inner">
              {breadcrumbs && <Breadcrumbs location={location} />}
              <h1
                className="title"
                dangerouslySetInnerHTML={{ __html: decodeEntities(header) }}
              />
              {subHeader && (
                <p
                  className="sub-title"
                  dangerouslySetInnerHTML={{
                    __html: decodeEntities(subHeader),
                  }}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </section>
  )
}
