import React from 'react';
import Accordion from '../../Accordion';
import RenderContent from '../../RenderContent'
import './Concertina.scss';

export const Concertina = props => {
  const { sectionTitle, concertina, sectionContent, enableSearchBar, id, searchBarPlaceholder, searchBarTitle, searchBarSubtitle } = props;
  const [displayedConcertina, setDisplayedConcertina] = React.useState(concertina);
  const [searchText, setSearchText] = React.useState("");

  React.useEffect(() => {
    if (searchText.length === 0) {
      setDisplayedConcertina(concertina);
    }

    const filteredConcertina = concertina.filter((section) => section.title.toLowerCase().includes(searchText.toLowerCase()) || section.content.toLowerCase().includes(searchText.toLowerCase()));

    setDisplayedConcertina(filteredConcertina);

  }, [searchText])

  return (
    <section className="concertina">
      <div className="wrap">
        <div className="inner">
          {enableSearchBar && (
            <div className="search-bar">
              {searchBarTitle.length > 0 && <span className="title">{searchBarTitle}</span>}
              <input name={`concertina-search-${id}`} type="text" value={searchText} onChange={(event) => setSearchText(event.target.value)} placeholder={searchBarPlaceholder} />
              {searchBarSubtitle.length > 0 && <span className="subtitle">{searchBarSubtitle}</span>}
            </div>
          )}
          {sectionTitle && <h3>{sectionTitle}</h3>}
          {sectionContent && <RenderContent content={sectionContent}/>}
          {displayedConcertina.length > 0 ? <Accordion concertina={displayedConcertina} /> : <span>No results found</span>}
        </div>
      </div>
    </section>
  );
};
