import React from 'react'
import { Image } from '../../Image'
import GatsbyLink from '../../GatsbyLink'
import './HeaderImage.scss'

export const HeaderImage = props => {
  const { imagePath, cta } = props
  return (
    <section className="header-image">
      {imagePath && (
        <div className="inner">
          {cta?.url ? (
            <GatsbyLink to={cta.url} className="action" decode={false}>
              <Image
                src={imagePath.source_url}
                className="header-image-background"
              />
            </GatsbyLink>
          ) : (
            <Image
              src={imagePath.source_url}
              className="header-image-background"
            />
          )}
        </div>
      )}
    </section>
  )
}
